.Bio {
    padding-top: 2em;
    padding-bottom: 0em;
    padding-left: 15%;
    padding-right: 15%;
    color: white;
    font-size: 1.25em;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
}

li {
    padding-bottom: 1em;
    list-style-type: none; 
}