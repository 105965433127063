.HeadlineRegion {
    background-image: linear-gradient(#000000a0 0%,#000 90%);
    margin-top: 2em;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
}

.CallToAction {
    padding: 1em;
    padding-left: 15%;
    padding-right: 15%;
    color: white;
    font-size: 1.25em;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.Signature {
    height: 5em;
}