.Container {
    padding-top: 0px;
    padding-bottom: 1em;
    padding-left: 15%;
    padding-right: 15%;
    color: white;
    font-size: 1.25em;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
}

.CertRow {
    background-color: #00000000;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 1em;
}

.CertsHeader {
    margin: auto;
    width:fit-content;
    margin-bottom: 1em;
}

.FillerCert {
    background-color: #00000000;
    width: 25%;
    max-width: 100px;
    padding-top: 1vw;
    padding-bottom: 1vw;
    padding-left: 4vw;
    padding-right: 4vw;
    float: left;
}