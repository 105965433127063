.Content {
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: white;
    height: calc(100% - 40px);
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-size: cover;
}