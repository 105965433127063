.Publications {
    padding-top: 0em;
    padding-bottom: 0em;
    padding-left: 15%;
    padding-right: 15%;
    color: white;
    font-size: 1.25em;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
}

.PublicationsHeader {
    margin: auto;
    width:fit-content;
    margin-bottom: 1em;
}