.Footer {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: black;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    height: 40px;
}
  
.Footer-text {
    color: white;
    background-color: #0000;
    font-size: 10px;
}


.Socials-Icon {
    color: white;
    padding-left: 5%;
    padding-right: 5%;
}

.SocialsRow {
    background-color: #00000000;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 2em;
}

.SocialsHeader {
    margin: auto;
    width:fit-content;
    margin-bottom: 1em;
}